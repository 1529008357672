.single-iu {
  margin: 16px 0;
  width: 100%;
  flex: 0 0 100%;

  &__icon {
    width: 40px;
    height: 40px;
    margin-bottom: 12px;
  }

  &__button {
    margin: 0;

    .single-iu {
      &__area {
        border: none;
        padding: 0 24px;
      }
    }
  }

  &__error {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
}
