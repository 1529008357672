#froala-editor {
  .fr-view table td,
  .fr-view table th {
    min-width: 100px;
  }

  .fr-wrapper {
    & > *:first-child:not(.fr-element),
    & > *:first-child:not(.fr-view) {
      display: none;
    }
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }
}
