@import '../../../../../assets/styles/base/media-query';
@import '../../../../../assets/styles/base/mixins';

.scanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.qrCodeContainer {
  height: 100%;
}

.overlayText {
  font-size: 18px;
  color: var(--text-default, #FFF);
  padding: 10px;
  border-radius: 5px;
}


.scannerTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  position: absolute;
  top: 10%;
  left: 0;
}

.arrowIcon {
  position: absolute;
  left: 16px; /* Устанавливаем отступ от левого края */
}
