@import '../../../assets/styles/base/media-query';
@import '../../../assets/styles/base/mixins';

.notfound {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 24px;

  &__box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0 1 100%;

    @include media(">=sm") {
      justify-content: center;
    }
  }

  &__line {
    width: 1px;
    background: #1F2937;
    margin: 24px 0;

    @include media(">=sm") {
      margin: 0 32px;
      align-self: stretch;
    }

    @include media("<sm") {
      flex: 0 0 100%;
      max-width: 50px;
      height: 1px;
    }
  }

  &__code {
    color: #1F2937;
    font-size: 64px;
    line-height: 1;
    letter-spacing: -1.28px;
    font-weight: 300;

    @include media("<sm") {
      flex: 0 0 100%;
    }
  }

  &__text {
    @include media("<sm") {
      flex: 0 0 100%;
    }
  }

  &__title {
    color: #1F2937;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 24px;

    @include media("<md") {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }

  &__descr,
  &__descr a {
    color: #1F2937;
    font-size: 16px;
    line-height: 1.3;

    @include media("<md") {
      font-size: 14px;
    }
  }

  &__descr a {
    font-weight: 500;
    letter-spacing: 0.32px;
    color: #9E28B5;
  }
}
