.sign {
    &-in,
    &-up {
      align-items: center;
      justify-content: space-between;
      background: var(--BG, linear-gradient(117deg, #E6E6E6 0%, #F3F3F4 100%));

      .MuiGrid-container {
        min-height: 100vh;
        max-width: 1000px;
        position: static;
        background: transparent;
        margin: 0 auto;

        @include media(">=md") {
          padding: 0 30px;
        }
      }

      a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: var(--Main-Black, #111827);
        @include transition(.2s ease-in-out);

        &:hover {
          opacity: 0.75;
        }
      }

      &__bg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI5NTgiIHZpZXdCb3g9IjAgMCAxNDQwIDk1OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xMTk2Ljk2IDEwMS4wODlMMTQwNy4xNCAtNThNMTE5Ni45NiAxMDEuMDg5TDk2MiAxMTMuNDg4TTExOTYuOTYgMTAxLjA4OUwxMzQ2LjI4IDQxNU0xNDA3LjE0IC01OEwxMTg5LjggLTE1LjczMTlNMTQwNy4xNCAtNThMMTQ4OSAyMDUuNzUzTTExODkuOCAtMTUuNzMxOUw5NjIgMTEzLjQ4OE0xMTg5LjggLTE1LjczMTlMMTI4Ni44OCAxOTguNzQ4TTk2MiAxMTMuNDg4TDExMjQuMDQgMzYzLjg3Nk0xMzQ2LjI4IDQxNUwxNDg5IDIwNS43NTNNMTM0Ni4yOCA0MTVMMTEyNC4wNCAzNjMuODc2TTE0ODkgMjA1Ljc1M0wxMjg2Ljg4IDE5OC43NDhNMTI4Ni44OCAxOTguNzQ4TDExMjQuMDQgMzYzLjg3NiIKICAgICAgICAgIHN0cm9rZT0iIzExMTgyNyIgc3Ryb2tlLW9wYWNpdHk9IjAuMSIvPgogICAgPHBhdGggZD0iTTg4LjkzMTUgNjk4TDU0LjIzNDQgNzE0LjUzNk04OC45MzE1IDY5OEwyNjQgNzgyLjY2Mk04OC45MzE1IDY5OEwxMy4yMjA2IDg2OS45NzZNNTQuMjM0NCA3MTQuNTM2TC0yMCA4NzkuNTI2TTU0LjIzNDQgNzE0LjUzNkwyMTAuNzAxIDc4OC4yMThNLTIwIDg3OS41MjZMMTM2Ljk2MiA5NTdNLTIwIDg3OS41MjZMMTMuMjIwNiA4NjkuOTc2TTEzNi45NjIgOTU3SDE5MC43N00xMzYuOTYyIDk1N0wyMTAuNzAxIDc4OC4yMThNMTkwLjc3IDk1N0wyNjQgNzgyLjY2Mk0xOTAuNzcgOTU3TDEzLjIyMDYgODY5Ljk3Nk0yNjQgNzgyLjY2MkwyMTAuNzAxIDc4OC4yMTgiCiAgICAgICAgICBzdHJva2U9IiMxMTE4MjciIHN0cm9rZS1vcGFjaXR5PSIwLjEiLz4KICAgIDxwYXRoIGQ9Ik0xNDQ3LjczIDYwNS45MTNMMTQ2Ni4xNyA1OTAuMDg1TTE0NDcuNzMgNjA1LjkxM0wxMzI2Ljg1IDU4My4zMjRNMTQ0Ny43MyA2MDUuOTEzTDE0NjUuNDkgNDg4LjU0N00xNDY2LjE3IDU5MC4wODVMMTQ4NC4xOSA0NzcuMjJNMTQ2Ni4xNyA1OTAuMDg1TDEzNTguNDYgNTcxLjEwNU0xNDg0LjE5IDQ3Ny4yMkwxMzc1LjU1IDQ1Ni4wMTJNMTQ4NC4xOSA0NzcuMjJMMTQ2NS40OSA0ODguNTQ3TTEzNzUuNTUgNDU2LjAxMkwxMzQyLjcgNDY0LjkyOU0xMzc1LjU1IDQ1Ni4wMTJMMTM1OC40NiA1NzEuMTA1TTEzNDIuNyA0NjQuOTI5TDEzMjYuODUgNTgzLjMyNE0xMzQyLjcgNDY0LjkyOUwxNDY1LjQ5IDQ4OC41NDdNMTMyNi44NSA1ODMuMzI0TDEzNTguNDYgNTcxLjEwNSIKICAgICAgICAgIHN0cm9rZT0iIzExMTgyNyIgc3Ryb2tlLW9wYWNpdHk9IjAuMSIvPgo8L3N2Zz4K');

        @include media("<sm") {
          background: var(--white);
        }
      }

      &__logo {
        padding: 40px 0 0 80px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex: 0 1 100%;

        @include media("<lg") {
          padding: 32px 80px 0 80px;
        }

        @include media("<md") {
          padding: 32px 64px 0 64px;
        }

        @include media("<sm") {
          padding: 16px 16px 0 16px;
        }
      }

      &__right {
        padding: 48px 40px;
        border-radius: 8px;
        background: var(--white);
        max-width: 500px;
        margin: 0 auto;

        @include media("<sm") {
          padding: 0;
        }
      }

      &__preloader {
        min-height: 300px;
      }

      &__step,
      &__step-1,
      &__step-2, {
        &__top {
          margin-bottom: 48px;
        }

        &__title {
          color: var(--Main-Black, #111827);
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }

        &__text {
          display: flex;
          align-items: center;
          margin: 16px 0 0;

          p {
            margin-right: 8px;
          }
        }

        &__icon {
          margin-bottom: 48px;
          display: block;
        }

        &__links {
          margin-top: 48px;
          border-top: 1px solid var(--default-gray-200);

          a {
            display: inline-flex;
            flex: 0 0 100%;
            margin: 8px 0;
            color: var(--Main-Black, #111827);
          }

          &__i {
            margin: 16px 0 0;

            &:last-child {
              margin-bottom: 0;
              margin-top: 8px;
            }
          }
        }
      }

      &__form {
        &__btn {
          margin: 32px 0 0 auto !important;
          display: block;

          @include media("<sm") {
            margin-top: 48px;
          }

          &-link {
            &-wrap {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }

    &-info {
      &__title {
        line-height: 1.5;
      }

      &__link {
        &-wrap {
          display: flex;
          align-items: center;
          padding: 12px 0 0;

          a {
            margin: 0 0 0 12px;
          }
        }
      }
    }
  }
